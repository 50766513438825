a {
  color: inherit;
  text-decoration: none;
}

ol,
ul,
li {
  list-style-type: none;
}
p {
  cursor: default;
}
* {
  box-sizing: border-box;
}

@font-face {
  font-family: "VT323";
  font-style: normal;
  font-weight: 100;
  src: url("../assets/fonts/VT323.woff2") format("woff2");
}

@font-face {
  font-family: "Cormorant Garamond";
  font-style: normal;
  font-weight: 100;
  src: url("../assets/fonts/Cormorant_Garamond_Med_ITalic.woff2")
    format("woff2");
}

@font-face {
  font-family: "Tfmadloud";
  font-style: normal;
  font-weight: 100;
  src: url("../assets/fonts/tfmadloud.woff2") format("woff2");
}

@font-face {
  font-family: "Unison";
  font-style: bold;
  font-weight: 100;
  src: url("../assets/fonts/Unison-Pro-Bold-Round.woff2") format("woff2");
}

p,
div {
  cursor: default;
}

.txtcen {
  text-align: center;
}

.darkenhov {
  transition: 0.1s ease-in-out;

  &:hover {
    transition: 0.1s ease-in-out;
    cursor: pointer;
    filter: brightness(0.8);
  }
}
