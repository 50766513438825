:root {
  --pink: #e5574c;
  --blue: #99bee8;
  --white: #ffffff;
  --purp: #d7b1c1;
  --blk: #000000;
  --cream: #ffd6c1;
  
  .pink {
    color: var(--pink);
  }

  .blue {
    color: var(--blue);
  }

  .purp {
    color: var(--purp);
  }

  .white {
    color: var(--white);
  }

  .blk {
    color: var(--blk);
  }

  .cream {
    color: var(--cream);
  }

  // fonts
  .gar {
    font-family: "Cormorant Garamond";
  }
  .vt {
    font-family: "VT323";
  }
  .tf {
    font-family: "Tfmadloud";
  }
  .uni {
    font-family: "Unison";
    font-weight: 100;
  }

  --ff-uni: "Unison";
  --ff-tf: "Tfmadloud";
  --ff-vt: "VT323";
  --ff-gar: "Cormorant Garamond";

  .pnt {
    cursor: pointer;
  }

  .und {
    text-decoration: underline;
    &:hover {
      filter: brightness(0.85);
    }
  }

  .uptx {
    text-transform: uppercase;
  }

  .captx {
    text-transform: capitalize;
  }
}
