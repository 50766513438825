@import "mixins.scss";
@import "global.scss";
@import "reset.scss";
@import "variables.scss";


.App {
  width: 100vw;
  display: flex;
  flex-direction: column;

  // loading screen

  .loadingscreen {
    height: 100vh;
    width: 100vw;
    position: fixed;
    z-index: 100000000;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: var(--purp);
    gap: 2.5rem;
    background: linear-gradient(180deg, #85c1f3 0%, #faa9ab 100%);

    @include mobile {
      gap: 3rem;
    }

    .catcar {
      height: 13rem;
      width: auto;

      @include mobile {
        height: 10rem;
      }
    }
    .txt {
      text-align: center;
      font-size: 1.5rem;
      max-width: 85vw;
      @include mobile {
        font-size: 1.35rem;
      }
    }
    .loader {
      width: 64px;
      height: 12px;
      background: radial-gradient(circle 6px, #fff 100%, transparent 0),
        radial-gradient(circle 6px, #fff 100%, transparent 0);
      background-size: 12px 12px;
      background-position: left center, right center;
      background-repeat: no-repeat;
      position: relative;
      @include mobile {
        transform: scale(0.9);
      }
    }
    .loader:before {
      content: "";
      position: absolute;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: var(--pink);
      inset: 0;
      margin: auto;
      animation: mvX 0.6s, mvY 0.3s;
      animation-timing-function: cubic-bezier(0.5, -300, 0.5, 300);
      animation-iteration-count: infinite;
    }

    @keyframes mvX {
      100% {
        left: 0.85px;
      }
    }
    @keyframes mvY {
      100% {
        top: 0.5px;
      }
    }
  }

  //   navbar area
  .navbar {
    background: linear-gradient(
      180deg,
      rgba(133, 193, 243, 0.85) 0%,
      rgba(250, 169, 171, 0.85) 100%
    );
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(8.8px);
    -webkit-backdrop-filter: blur(8.8px);
    width: 100vw;
    position: fixed;
    // border-bottom: 5px solid white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 2rem;
    position: sticky;
    top: 0px;
    z-index: 5000;
    @include mobile {
      padding: 0.5rem 1rem;
      position: fixed;
    }
    .gingerlogo {
      height: 90px;
      width: auto;
      @include mobile {
        height: 50px;
      }
    }
    .navarea {
      display: flex;
      flex-direction: row;
      gap: 4rem;
      font-size: 1.2rem;
      /* From https://css.glass */
      background: rgba(255, 214, 193, 0.649);
      border-radius: 5rem;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(8.6px);
      -webkit-backdrop-filter: blur(8.6px);
      border: 1px solid rgba(255, 186, 152, 1);
      padding: 1rem 4rem;
      @include mobile {
        display: none;
      }
      .nav-item {
        cursor: pointer;
        transition: 0.3s;
        text-transform: capitalize;
        &:hover {
          transition: 0.3s;
          color: rgba(255, 214, 193);
          background-color: var(--blk);
          padding: 1px 20px;
          margin: -1px -20px;
          border-radius: 1rem;
        }
      }
    }

    .community-btn {
      border: 2px solid white;
      color: #ffe8dd;
      cursor: pointer;
      font-size: 1.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 50px;
      width: 150px;
      border-radius: 0.3rem;
      background-image: url(../assets/imgs/texttures/pinktexturepng.png);
      background-size: cover;
      gap: 1rem;
      transition: 0.3s;

      &:hover {
        transition: 0.3s;
        border-radius: 5rem;
        filter: brightness(0.95);
        background-image: url(../assets/imgs/stripes.gif);
        color: black;
      }
      @include mobile {
        display: none;
      }
    }

    .mobilenav {
      display: none;
      @include mobile {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: linear-gradient(
          180deg,
          rgba(133, 193, 243, 1) 0%,
          rgba(250, 169, 171, 1) 100%
        );
        position: fixed;
        height: 100vh;
        width: 100vw;
        z-index: 59000;
        left: 0;
        top: 0;
        gap: 5rem;
        .closeico {
          position: absolute;
          right: 1rem;
          top: 1rem;
        }
        .gingerlogo-nav {
          height: 5rem;
          width: auto;
        }
        .navarea {
          display: flex;
          flex-direction: column;
          border-radius: 0.7rem;
          text-align: center;
          gap: 3rem;
          font-size: 1.25rem;
          text-transform: lowercase;
          padding: 1.5rem 3rem;
          // .nav-item {
          // }
        }
        .community-btn {
          display: flex;
        }
      }
    }
  }

  .toparea {
    background-color: white;
    color: black;
    display: flex;
    flex-direction: column;
    gap: 0rem;
    align-items: center;
    background-image: url(../assets//imgs//texttures/pinktexturepng.png);
    @include mobile {
      padding-top: 3.2rem;
    }
    .white-area {
      margin-top: 1.5rem;
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
      // border-top: 5px solid black;

      .area1 {
        height: 80px;
        border: 2px solid black;
        border-radius: 1px;
        width: 97.5vw;
        overflow: hidden;
        background-color: white;
        background: linear-gradient(180deg, #85c1f3 0%, #faa9ab 100%);
        color: white;
        @include mobile {
          height: 60px;
        }
        .rolling-text {
          display: flex;
          align-items: center;
          white-space: nowrap;
          display: flex;
          flex-direction: row;
          padding: 0;
          margin: 0;
          align-items: center;
          // width: 97.5vw;
        }
        .tf {
          margin-top: 0.7rem;
          font-size: 3.5rem;
          margin-right: 2rem;
          @include mobile {
            font-size: 2.2rem;
            margin-right: 0.85rem;
          }
        }
        .vt {
          font-size: 2rem;
          padding: 0;
          margin: 0;
          margin-right: 2rem;
          @include mobile {
            font-size: 1.7rem;
            margin-right: 0.85rem;
          }
        }
      }

      .area2 {
        height: auto;
        border: 2px solid black;
        border-radius: 1px;
        width: 97.5vw;
        overflow: hidden;
        display: flex;
        flex-direction: row;
        background-image: url(../assets/imgs/cute-cat-bg2.png);
        background-position: 104%;
        color: black;
        background-size: contain;
        background-repeat: no-repeat;
        background-color: #ffd9a4;
        @include mobile {
          background-position: bottom left;

          background-size: 30%;
        }
        .left-side {
          position: relative;
          text-transform: lowercase;
          width: 40%;
          border-right: 2px solid black;
          font-size: 1.6rem;
          padding: 1.5rem 1.25rem;
          display: flex;
          flex-direction: column;
          gap: 0.3rem;
          @include mobile {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1.4rem;
            font-size: 1.5rem;
          }
          .shrink {
            font-size: 1.18rem;
            @include mobile {
            }
          }
          .catstamp {
            width: auto;
            height: 80px;
            position: absolute;
            right: -70px;
            top: 50%;
            transform: translateY(-50%);
            @include mobile {
              height: 60px;
              right: -50px;
            }
          }
        }
        .right-side {
          width: 60%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1.4rem;
          text-transform: capitalize;
          @include mobile {
            font-size: 1.3rem;
            padding: 1rem 0.4rem;
          }
          .txt {
            max-width: 75%;
          }
        }
      }
    }

    .img-area {
      margin-top: 1.5rem;
      position: relative;
      .topbanner {
        width: 100vw;
        height: auto;
        @include mobile {
          border-top: 2px solid black;

          width: auto;
          height: 65vh;
        }
      }

      .rotatingtxts {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        font-size: 5rem;
        @include mobile {
          font-size: 3rem;
        }
        .word {
          padding-left: 1.25rem;
          padding-right: 1.25rem;
        }
      }

      .center-cat-face-area {
        z-index: 10;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: row;
        @include mobile {
          gap: 3.2rem;
        }
        .pl {
          @include mobile {
            height: 20rem;
            width: auto;
          }
        }
        .pr {
          @include mobile {
            height: 20rem;
            width: auto;
          }
        }

        .catface {
          height: 250px;
          width: auto;
          margin-top: 50%;
          @include mobile {
            margin-top: 15%;
            height: 175px;
          }
        }
      }

      .buy-btn {
        z-index: 10;
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 5rem;
        right: 2rem;
        background-color: #ee2d1f;
        border-radius: 2rem;
        width: 15rem;
        height: 3rem;
        gap: 0.5rem;
        border: 3px solid black;
        font-size: 1.5rem;
        .uniswaplogo {
          height: 20px;
          width: auto;
        }
        @include mobile {
          font-size: 1.3rem;
          width: 14rem;
          height: 2.8rem;
          gap: 0.5rem;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }

  .curveblu {
    z-index: 5;
    margin-top: -4.5rem;
    width: 100vw;
    @include mobile {
      margin-top: -2rem;
    }
  }
  .aboutarea {
    background: linear-gradient(180deg, #85c1f3 0%, #faa9ab 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-bottom: 10rem;
    @include mobile {
      padding-bottom: 7rem;
    }
    .abt {
      position: absolute;
      font-size: 9rem;
      top: -1rem;
      left: 5%;
      z-index: 9;
      @include mobile {
        left: 50%;
        transform: translateX(-50%);
        text-wrap: nowrap;
        font-size: 4rem;
        top: 2.25rem;
      }
    }
    .text-cnt {
      z-index: 10;
      margin-top: 5rem;
      color: white;
      background-image: url(../assets/imgs/texttures/pinktexturepng.png);
      border-radius: 1rem;
      border: 3px solid white;
      background-size: cover;
      background-repeat: no-repeat;
      width: 80rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      @include mobile {
        width: 370px;
        margin-top: 4.75rem;
      }
      .line1 {
        margin-top: 2rem;
        font-size: 2.5rem;
        white-space: nowrap;
        display: flex;
        flex-direction: row;
        align-items: center;
        @include mobile {
          gap: 0.6rem;
        }
        .catfacedet {
          height: 6.5rem;
          width: auto;
          padding-right: 10px;
          padding-left: 10px;
          @include mobile {
            height: 6rem;
          }
        }
        .tf {
          margin-top: 10px;
          font-size: 5rem;
        }
      }
      .twoduo {
        display: flex;
        flex-direction: row;
        .one {
          .line2 {
            margin-top: 10px;

            font-size: 3rem;
            padding-right: 2rem;
          }

          .line3 {
            margin-top: 15px;
            font-size: 2rem;
            word-spacing: 25px;
            .vt {
              font-size: 4rem;
              padding-right: 1.25rem;
            }
          }
        }
        .two {
          display: flex;
          flex-direction: row;
          .catball {
            height: 150px;
            width: auto;
          }
          .txts {
            display: flex;
            flex-direction: column;
            padding-left: 1rem;
            .cat {
              margin-top: 15px;
              font-size: 2rem;
            }
            .the {
              margin-top: 50px;
              font-size: 2rem;
            }
          }
        }
      }
      // notwoduo on mobile
      @include mobile {
        .line2 {
          padding-left: 1rem;

          margin-top: 10px;

          font-size: 1.75rem;
          padding-right: 2rem;
        }

        .line3 {
          padding-left: 1rem;
          margin-top: 2px;
          font-size: 1.75rem;
          word-spacing: auto;
          .vt {
            font-size: 3rem;
            padding-right: 1rem;
          }
        }
        .mid-painting {
          // margin-top: 0.5rem;
          // margin-bottom: 0.5rem;
          height: 12rem;
          width: auto;
        }
      }

      .line4 {
        margin-top: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 2.5rem;
        gap: 1rem;
        .vt {
          font-size: 4.5rem;
        }
        @include mobile {
          gap: 0.3rem;
          .vt {
            font-size: 3.5rem;
          }
        }
      }
      .line4b {
        @include mobile {
          padding-left: 2.5rem;
          font-size: 1.7rem;
        }
      }
      .line5 {
        margin-top: 20px;
        font-size: 2.25rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1.5rem;
        .supcnt {
          display: flex;
          align-items: center;
          gap: 0.75rem;
          color: black;
          background-color: white;
          padding: 0.4rem 1.5rem;
          border-radius: 3rem;
        }
        @include mobile {
          margin-top: 20px;
          .supcnt {
            padding: 0.4rem 1.2rem;
            font-size: 1.7rem;
            text-wrap: nowrap;
          }
        }
      }

      .miscline {
        margin-top: 2rem;
        text-align: center;
        font-size: 1.5rem;
        max-width: 70%;
        @include mobile {
          font-size: 1rem;
          margin-top: 1rem;
          max-width: 85%;
        }
      }
      .bookico {
        margin-top: 1rem;
        margin-bottom: 2rem;
        @include mobile {
          margin-bottom: 2rem;
          transform: scale(0.8);
        }
      }
      .painting {
        position: absolute;
        height: 10rem;
        width: auto;
        bottom: 6rem;
        right: 5rem;
        @include mobile {
          display: none;
        }
      }
      .catcar {
        position: absolute;
        height: 10rem;
        width: auto;
        @include mobile {
          height: 7rem;
        }
      }
    }
  }

  .onboard {
    background-image: url(../assets/imgs/texttures/pinktexturepng.png);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 7rem;
    @include mobile {
      padding-bottom: 4rem;
      background-size: cover;
    }
    .area {
      margin-top: -4rem;
      rotate: 1deg;
      height: 80px;
      border: 2px solid black;
      border-radius: 1px;
      width: 100vw;
      overflow: hidden;
      background-color: white;
      background: linear-gradient(180deg, #85c1f3 0%, #faa9ab 100%);
      // background-image: url(../assets/imgs/texttures/bluetexture.png);
      background-color: white;
      color: black;
      @include mobile {
        margin-top: -1rem;
        height: 60px;
        width: 105vw;
      }
      .rolling-text {
        display: flex;
        align-items: center;
        white-space: nowrap;
        display: flex;
        flex-direction: row;
        padding: 0;
        margin: 0;
        align-items: center;
        // width: 97.5vw;
      }
      .tf {
        margin-top: 0.7rem;
        font-size: 3.5rem;
        margin-right: 2rem;
        @include mobile {
          font-size: 2.2rem;
          margin-right: 0.85rem;
        }
      }
      .vt {
        font-size: 2rem;
        padding: 0;
        margin: 0;
        margin-right: 2rem;
        @include mobile {
          font-size: 1.7rem;
          margin-right: 0.85rem;
        }
      }
    }

    .tog {
      display: flex;
      align-items: center;
      flex-direction: row;
      position: relative;
      padding-top: 3rem;
      padding-bottom: 4rem;
      margin-left: 8rem;
      @include mobile {
        padding-top: 5.6rem;
        margin-left: 0rem;
        padding-bottom: 5rem;
        flex-direction: column;
      }
      .sidetxt {
        position: absolute;
        top: 16rem;
        left: -14.5rem;
        color: white;
        rotate: -90deg;
        font-size: 6rem;
        z-index: 4;
        @include mobile {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          text-wrap: nowrap;
          font-size: 3.7rem;
          top: 3rem;

          rotate: 0deg;
        }
      }
    }
    .onboard-cnt {
      margin-left: 3rem;
      background-image: url(../assets/imgs/texttures/bluetexture.png);
      border-radius: 1rem;
      width: 70rem;
      border: 3px solid white;
      z-index: 5;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      @include mobile {
        margin-left: 0;
        width: 365px;
        max-width: 99vw;
      }
      .title-area {
        margin-top: 1.25rem;
        background-color: white;
        border-radius: 1.5rem;
        position: relative;
        font-size: 3rem;
        text-transform: uppercase;
        padding: 0.2rem 4rem;
        @include mobile {
          text-wrap: nowrap;
          padding: 0.2rem 0.3rem;
          font-size: 1.35rem;
        }
        .catback {
          position: absolute;
          left: -1.75rem;
          height: 5.25rem;
          width: auto;
          top: 50%;
          transform: translateY(-50%);
          @include mobile {
            top: -0.8rem;
            left: -1rem;
            height: 3rem;
          }
        }
      }
      .below-title {
        margin-top: 7px;
        font-size: 1.55rem;
        @include mobile {
          margin-top: 10px;
          font-size: 1.25rem;
        }
      }
      .content-areaonb {
        margin-top: 1.75rem;
        margin-bottom: 1.75;
        list-style: decimal;
        font-size: 1.7rem;
        display: flex;
        flex-direction: column;
        gap: 1.5rem;
        max-width: 95%;
        .circ {
          color: black;
          background-color: white;
          border-radius: 2rem;
          padding: 0.2rem 0.2rem;
          margin-left: -0.2rem;
        }
        @include mobile {
          margin-right: 1.5rem;
          font-size: 1.4rem;
          gap: 1.5rem;
          max-width: 100%;
          // .circ {
          // }
        }
      }
    }
    .circ {
      color: black;
      background-color: white;
      border-radius: 2rem;
      padding: 0.2rem 0.5rem;

      font-size: 1.4rem;
      @include mobile {
        font-size: 1.15rem;
        text-wrap:  nowrap;

      }
    }
    .leaptxt {
      font-size: 1.4rem;
      margin-bottom: 1.5rem;
      @include mobile {
        margin-top: 5px;
        font-size: 1.2rem;
      }
    }
  }

  .footer {
    background: linear-gradient(
      180deg,
      rgba(133, 193, 243, 1) 0%,
      rgba(250, 169, 171, 1) 100%
    );
    // box-shadow: 0 4px 30px rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(8.8px);
    -webkit-backdrop-filter: blur(8.8px);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @include mobile {
      padding: 1.5rem 1rem;
    }

    .arcade {
      height: 12rem;
      width: auto;
      @include mobile {
        display: none;
      }
    }
    .centre-icons {
      background: rgba(255, 214, 193, 0.649);
      border-radius: 5rem;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
      backdrop-filter: blur(8.6px);
      -webkit-backdrop-filter: blur(8.6px);
      border: 1px solid rgba(255, 186, 152, 1);
      padding: 1rem 4rem;
      display: flex;
      gap: 4rem;
      .icon {
        cursor: pointer;
        transition: 0.3s;
        text-transform: capitalize;
        font-size: 1.25rem;
        color: black;
        &:hover {
          transition: 0.3s;
          color: rgba(255, 214, 193);
          background-color: var(--blk);
          padding: 0.6rem;
          margin: -0.6rem;
          border-radius: 1rem;
          padding-bottom: 0.9rem;
          margin-bottom: -0.9rem;
        }
      }
      @include mobile {
        gap: 2rem;
        padding: 0.3rem 1.2rem 0.8rem 1.2rem;
        // flex-direction: column;
        border-radius: 0.7rem;
        background: rgba(255, 214, 193, 0.5);
transform: scale(0.9);
        .icon {
          &:hover {
            color: black;
            background-color: transparent;

          }
        }
      }
    }

    .about-area {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 0.1rem 1rem;

      @include mobile {
        padding: 0;
        align-items:end;
        padding-right: 0.5rem;
      }
      .logo {
        height: 5rem;
        width: auto;
        @include mobile {
          height: 4rem;
        }
      }
      .title-txt {
        font-size: 1.15rem;
        @include mobile {
          // margin-left: 3rem;
          text-align: right;
        }
      }
      .sub-txt {
        text-align: center;
        font-size: 1.05rem;
        @include mobile {
          text-transform: lowercase;
          text-align: right;
        }
      }
    }
  }
}

.curveblu-footer {
  margin-top: -5rem;
  width: 100vw;
  @include mobile {
    margin-top: -5rem;
    margin-bottom: -10px;
  }
}
